

















































































































































































/* Note: To anyone touching this component.  fetchDB method is very sensitive to timing, touch it at your own risk */
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
export default Vue.extend({
  name: 'remote-config-viewer',
  data() {
    return {
      build: {} as Record<string, any>,
      loadingConfig: false,
      isDeveloper: false,
      isQA: false,
      help: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.build = (window as any)['funi-build'] as Record<string, any>;
      this.updateLocalUserToggles();
    });
  },
  computed: {
    ...mapGetters([ 'getValue' ]),
    ...mapState({
      config: (state: any) => state.config,
    }),
    env(): string {
      return this.build.env;
    },
    appConfig(): string {
      return this.build.config;
    },
    options(): Record<string, any> {
      return this.config.remoteConfig.app.options;
    },
    defaultConfig(): Record<string, any> {
      return this.config.remoteConfig.defaultConfig;
    },
  },
  methods: {
    featureClass(val1: string, val2: string): string {
      return val1 !== val2 ? 'feature-row' : '';
    },
    async fetchDB(): Promise<void> {
      await this.config.analytics.setUserProperties({
        'is_developer': window.localStorage.getItem('isDeveloper') === 'true' ? 1 : 0,
        'is_qa': window.localStorage.getItem('isQA') === 'true' ? 1 : 0,
      });
      this.config.remoteConfig.settings.minimumFetchIntervalMillis = 1000;
      await new Promise((resolve) => {
        setTimeout(async () => {
          await this.config.remoteConfig.fetchAndActivate();
          this.config.remoteConfig.settings.minimumFetchIntervalMillis = 43200000;
          setTimeout(() => {
            this.$forceUpdate();
            resolve();
          }, 2000);
        }, 2000);
      });
    },
    async refreshDB(): Promise<void> {
      this.loadingConfig = true;
      await this.fetchDB();
      this.loadingConfig = false;
    },
    resetDB(): void {
      this.resetFBDatabase();
      window.location.reload(true);
    },
    resetFBDatabase(): void {
      indexedDB.deleteDatabase('firebase-installations-database');
      indexedDB.deleteDatabase('firebase_remote_config');
    },
    updateLocalUserToggles() {
      this.isQA = window.localStorage.getItem('isQA') === 'true';
      this.isDeveloper = window.localStorage.getItem('isDeveloper') === 'true';
    },
    async toggleMode(mode: string): Promise<void> {
      window.localStorage.setItem(mode, (this as any)[mode] ? 'false' : 'true');
      this.updateLocalUserToggles();
      await this.fetchDB();
    }
  },
});
