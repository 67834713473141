import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

import en from './locales/en.json';

Vue.use(VueI18n);

const messages = {
  en,
} as LocaleMessages;

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});