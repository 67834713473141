





























import Vue from 'vue';

export default Vue.extend({
  name: 'content-faq',
  props: {
    data: Array,
    isLightTheme: Boolean,
  },
});
