export const PREMIUM_SUBSCRIPTION_TIER_CUTOFF = 10;
export const ASIAN_LANGUAGES = [
  'ja',
  'ko',
  'zh',
  'zh_MN',
  'zh_CT',
  'zh_TW',
  'zh_CN',
  'cn'
];
