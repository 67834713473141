import UserInfoData from './UserInfoData';
import { compareStrings } from '@funimation/comp-utils';


export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export class UserInfo {
  constructor(config: Record<string, any>) {
    this.config = {
      base: config.service + '/v1',
    };
  }
    config: any;
    saveUserData(userData: any): void {
      window.localStorage.setItem('userData', JSON.stringify(userData));
      return;
    }
    setCookie(cname: string, cvalue: string, exdays: number, path = '/') {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  
      const expires = 'expires=' + d.toUTCString();
      const domain = 'domain=.' + window.location.hostname;
      
      document.cookie = `${cname}=${cvalue};path=${path};${domain};${expires};`;
      
    }
    deleteCookie(cname: string) {
      const domain = 'domain=' + window.location.hostname;
      // Allowing for legacy domains, which are passing in the period as part of the domain
      const legacyDomain = 'domain=.' + window.location.hostname;
      const path = 'path=/';
      if (getCookie(cname)) {
        // Reset cookies for new and legacy domains
        document.cookie = `${cname}= ; ${path}; ${domain}; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
        document.cookie = `${cname}= ; ${path}; ${legacyDomain}; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    }
    declare window: any;
  
    async fetchUserRegion(): Promise<any> {
      return { region: window.region, isSupportedRegion: window.isSupportedRegion };
    }
    fetchUserLanguage(): any {
      const esRegions = [
        'MX',
        'CL',
        'PE',
        'CO'
      ];
      let locale;
      if (esRegions.includes(window.region)) {
        locale = 'es';
      } else if (compareStrings(window.region, 'BR')) {
        locale = 'pt';
      }
      else {
        locale = 'en';
      }
      return { defaultLanguage: locale };
    }
    fetchUserAudioLanguage(): any {
      const esRegions = [
        'MX',
        'CL',
        'PE',
        'CO'
      ];
  
      let locale;
      if (esRegions.includes(window.region)) {
        locale = 'es';
      } else if (compareStrings(window.region, 'BR')) {
        locale = 'pt';
      }
      else {
        locale = 'en';
      }
  
      const cookiedLanguage = getCookie('LANGUAGE_PREFERENCE');
      const cookiedLocale = cookiedLanguage.split('-')[0];
      const lang = (cookiedLocale || locale);
  
      return { defaultLanguage: lang };
    }
    logOutUser(): UserInfoData {
      const noUserData = JSON.stringify({ noUserData: true });
      const userData = JSON.parse(noUserData);
      const token = '';
      const deviceid = '';
      if (window.localStorage.getItem('userData') !== null) {
        window.localStorage.removeItem('userData');
      }
      if (window.localStorage.getItem('userId') !== null) {
        window.localStorage.removeItem('userId');
      }
      this.deleteCookie('src_token');
      this.deleteCookie('deviceid');
      this.deleteCookie('PIsession');
      this.deleteCookie('src_user_id');
      this.setCookie('userState', 'Anonymous', 0); // session cookie
      this.setCookie('subscriptionMethod', '', 0); // session cookie
      return {
        token,
        deviceid,
        userData,
      };
    }
}
