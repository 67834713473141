declare global {
  interface Window {
    firebase: any;
  }
}
const defaultConfig = {};
const defaultFirebaseConfig = {
  apiKey: 'AIzaSyAP8kvwpllBxoKZ45nur56x_0OWuGzQfRU',
  authDomain: 'funimation-dev-43798.firebaseapp.com',
  databaseURL: 'https://funimation-dev-43798.firebaseio.com',
  projectId: 'funimation-dev-43798',
  storageBucket: 'funimation-dev-43798.appspot.com',
  messagingSenderId: '361094784503',
  appId: '1:361094784503:web:a60113ec3d8d9722ae7af2',
  measurementId: 'G-CMNR1L920E',
};
async function setupFirebase(config: any = defaultConfig): Promise<any> {
  config.firebaseConfig = config.firebaseConfig || defaultFirebaseConfig;
  window.firebase.initializeApp(config.firebaseConfig);
  return window.firebase;
}
export default setupFirebase;
