export const API_URLS = {
  geoService: {
    regionCheck: () => {
      return '/region/check';
    },
  },
  userInfoService: '/api/source/user/',
  userProfileService: '/api/source/auth/profile/',
  pinService: '/v1/pin/activate',
};
